import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import error404 from "../assets/error-404.jpg"
import { Link } from 'react-router-dom'

const Error404 = () => {
    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:EMPLOYEE::404 Page</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="error404page">
                            <Link to="/dashboard">
                                <img src={error404} alt={error404} />
                            </Link>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Error404