import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import { activeStatus, addDataAPI, editDataFromList, fetchAllDataAPI, removeDataFromList, updateDataAPI } from '../../utils/api'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { FaRegHandPointRight } from 'react-icons/fa'
import { GiMushroomGills } from 'react-icons/gi'

const initialState = {
    sproblem_id: '',
    sproblem_name: '',
}

const SubProblem = () => {
    const { slug } = useParams();
    const [problem, setProblem] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const subadminid = localStorage.getItem('subadmindata');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [values, setUserValue] = useState({});
    const [validated, setValidated] = useState(false);
    const [sproblem, setSProblem] = useState(initialState);
    const [sproblemid, setSproblemid] = useState("");

    const [editshow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (subadminid) {
            navigate(`/all-subadmin-sub-problem/${slug}`);
            getAllSubProblem()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const getAllSubProblem = () => {
        setIsLoading(true)
        fetchAllDataAPI(`sub-admin-all-sub-problem/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setProblem(...[res.data.problemdata]);
            }
            else {
                setIsLoading(false);
                toast.warning("Check your connection");
            }
        })
    }
    const handleProblemActive = (problemid) => {
        activeStatus('sub-admin-active-sub-problem-status', problemid).then((res) => {
            if (res.data.status === 1) {
                getAllSubProblem()
            }
        })
    }
    const handleProblemInactive = (problemid) => {
        activeStatus('sub-admin-inactive-sub-problem-status', problemid).then((res) => {
            if (res.data.status === 1) {
                getAllSubProblem()
            }
        })
    }
    const handleProblemRemove = (problemid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-sub-problem', problemid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllSubProblem()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const datavalue = {
                sproblem_name: values?.sproblem_name,
                pro_id: slug,
            };
            addDataAPI('sub-admin-add-sub-problem', datavalue).then((res) => {
                if (res.data.status === 1) {
                    getAllSubProblem()
                    handleClose()
                    toast.success("Problem has been added successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    const handleEditSubProblem = (problemid) => {
        setEditShow(true);
        editDataFromList(`sub-admin-get-single-sub-problem-data/${problemid}`).then((res) => {
            if (res.data.status === 1) {
                setSProblem(...[res.data.problemdata])
                setSproblemid(res.data.problemdata.sproblem_id)
            }
        })
    }
    const handleUpdateInputs = async (e) => {
        setSProblem({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-sub-problem-data/${sproblemid}`, sproblem).then((res) => {
                if (res.data.status === 1) {
                    getAllSubProblem()
                    handleEditClose()
                    toast.success("Problem has been updated successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="companypage">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Sub Problem</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} method="POST" onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationChanelName">
                                <Form.Label>Sub Problem Name</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><GiMushroomGills /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Sub Problem Name"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        name="sproblem_name"
                                        onChange={handleInputs}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please provide a valid problem name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Button type="submit">Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={editshow} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Sub Problem</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationChanelName">
                                <Form.Label>Sub Problem Name</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend"><GiMushroomGills /></InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Sub Problem Name"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        name="sproblem_name"
                                        onChange={handleUpdateInputs}
                                        value={sproblem?.sproblem_name}
                                    />
                                    <input type="hidden" name="sproblem_id" value={sproblem?.sproblem_id} />
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please provide a valid problem name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Button type="submit">Update</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Helmet>
                <title>Satkartar::Subadmin::Sub Problem</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between mb-2">
                        <h5 className="mb-0 text-uppercase">All Sub Problem</h5>
                        <button onClick={handleShow} className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Sub Problem</button>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                    </>


                                    :
                                    problem?.map((problem, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{problem?.sproblem_name}</td>
                                            <td>
                                                {(() => {
                                                    if (problem?.sproblem_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success statustype" onClick={() => handleProblemActive(problem?.sproblem_id)}>{problem?.sproblem_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger statustype" onClick={() => handleProblemInactive(problem?.sproblem_id)}>{problem?.sproblem_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <button onClick={() => handleEditSubProblem(problem?.sproblem_id)} className="btnEdit"><AiFillEdit /></button>
                                                <button className="btnDelete" onClick={() => handleProblemRemove(problem?.sproblem_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default SubProblem