import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaHouseDamage } from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';

const initialState = {
    couriername: '',
    dstatus_name: '',
    delivery_status_code: '',
}

const EditDeliveryStatus = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [validated, setValidated] = useState(false);
    const [courier, setCourier] = useState([]);


    useEffect(() => {

        getSingleDeliveryStatus(slug)
        getAllCourierPatner()

    }, [slug])

    const getAllCourierPatner = () => {
        fetchAllDataAPI('sub-admin-get-active-courier-data').then((res) => {
            if (res.data.status === 1) {
                setCourier(...[res.data.courierdata])
            }
        })
    }

    const getSingleDeliveryStatus = (slug) => {
        editDataFromList(`sub-admin-get-single-delivery-status-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.statusdata])
            }
        })
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateDeliveryStatus = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-delivery-status-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-delivery-status");
                    toast.success("Delivery Status has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Delivery Status already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::Edit Delivery Status</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaHouseDamage className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Delivery Status</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateDeliveryStatus}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                                        <Form.Label>Select Courier</Form.Label>
                                        <Form.Select
                                            required
                                            placeholder="Gender Name"
                                            name="couriername"
                                            onChange={handleInputs}>
                                            <option value={values?.couriername}>{values?.couriername}</option>
                                            {courier?.map((courier, index) => (
                                                <option key={index} value={courier?.courier_name}>{courier?.courier_name}</option>
                                            ))}

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a call type
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationDeliveryName">
                                        <Form.Label>Delivery Status Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Order Status Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="dstatus_name"
                                            onChange={handleInputs}
                                            value={values?.dstatus_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid status name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationStatusCode">
                                        <Form.Label>Status Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Status Code"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="delivery_status_code"
                                            onChange={handleInputs}
                                            pattern="[0-9]*"
                                            value={values?.delivery_status_code}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid status code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Update</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditDeliveryStatus