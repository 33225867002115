import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaRegHandPointRight, FaRegUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { addDataAPI, fetchAllDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';


const AddProcessNumber = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState({});
    const [validated, setValidated] = useState(false);
    const [ppc, setPPC] = useState([]);
    const [language, setLanguage] = useState([]);

    const subadminid = localStorage.getItem('subadmindata');

    useEffect(() => {
        if (subadminid) {
            navigate('/add-subadmin-process');
            getPPCLanguage();
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getPPCLanguage = () => {
        fetchAllDataAPI('sub-admin-get-ppc-language').then((res)=>{
            if(res.data.status===1){
                setPPC(...[res.data.ppc]);
                setLanguage(...[res.data.language]);
            }
        })
    }

    const handleInputs = async (e) => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddChanel = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const datavalue = {
                process_name: values?.process_name,
                process_mobile: values?.process_mobile,
                process_product: values?.process_product,
                process_ppc: values?.process_ppc,
                process_language: values?.process_language,
                process_handle_by: values?.process_handle_by,
                subadmin_id: subadminid,
            };
            addDataAPI('sub-admin-add-process-numbers', datavalue).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-process");
                    toast.success("Process has been added successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("This Process already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::ADD Process Number</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaRegUser className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Process Number</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleAddChanel}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationName">
                                        <Form.Label>Process Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Process Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_name"
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid process name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <Form.Label>Process Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Process Number"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_mobile"
                                            pattern="[6789][0-9]{9}"
                                            maxLength={10}
                                            minLength={10}
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid process number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Product Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Product Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_product"
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid product name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Select PPC Team</Form.Label>
                                        <Form.Select required
                                            placeholder="Category Name"
                                            name="process_ppc"
                                            onChange={handleInputs}>
                                            <option value="">Select PPC Team</option>
                                            {ppc?.map((ppc, index)=>(
                                                <option key={index} value={ppc?.ppc_id}>{ppc?.ppc_name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid ppc team
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Select Language</Form.Label>
                                        <Form.Select required
                                            placeholder="Category Name"
                                            name="process_language"
                                            onChange={handleInputs}>
                                            <option value="">Select Language</option>
                                            {language?.map((language, index)=>(
                                                <option key={index} value={language?.language_id}>{language?.language_name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid language
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationEmail">
                                        <Form.Label>Handle By</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Handler Name"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="process_handle_by"
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid handle name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Add</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddProcessNumber