import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footerSection">
            <div className="footer">
                <Link to="/dashboard">Sat Kartar CRM Ltd © 2024. All Rights Reserved</Link>
            </div>
        </div>
    )
}

export default Footer
