import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { getSingleDataFromDatabase, userUploadProfilePicAPI } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { BASEURLS } from '../utils/BaseUrl'
import { MdCloudUpload } from 'react-icons/md'
import moment from 'moment'

const MyProfile = () => {
    const subadminid = localStorage.getItem("subadmindata");
    const [subadmin, setSubadmin] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [file, setFile] = useState('');

    useEffect(() => {
        if (subadminid) {
            getDashboardSingleData(subadminid);
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getDashboardSingleData = (subadminid) => {
        setIsloading(true);
        getSingleDataFromDatabase(`sub-admin-get-single-data/${subadminid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setSubadmin(...[res.data.subadmindata]);
            }
            else {
                setIsloading(true);
                toast.error("Check you connection");
            }
        })
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("subagent_foto", file);
                userUploadProfilePicAPI(`sub-admin-upload-profile-pic/${subadminid}`, data).then((res) => {
                    if (res.data.status === 1) {
                        window.location.reload();
                        toast.success("profile pic updated successfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);
    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-6">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="card-header border-bottom bg-transparent">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h6 className="mb-0" style={{ color: "#03a9f4", fontWeight: "500" }}>My Profile</h6>
                                    </div>
                                </div>
                            </div>
                            {isLoading ?
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-xl-4">
                                            <Skeleton variant="rectangular" width={210} height={218} />
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-8">
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-xl-4">
                                            <div className="profileimg">
                                                <img src={BASEURLS(subadmin?.subadmin_foto)} alt={subadmin?.subadmin_foto} />
                                                <label className="labelProfilePic btn btn-sm btn-info" htmlFor="subadmin_foto"><MdCloudUpload /> Change Profile Picture</label>
                                                <input className="profilePic" type="file" id='subadmin_foto' name="subadmin_foto" onChange={(e) => setFile(e.target.files[0])} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8 col-xl-8">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Name:</span>
                                                        <span className="value badge badge-success">{subadmin?.subadmin_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Email:</span>
                                                        <span className="value">{subadmin?.subadmin_email}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Mobile:</span>
                                                        <span className="value">{subadmin?.subadmin_mobile}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-12">
                                                    <div className="profilebody">
                                                        <span className="key">Create Date:</span>
                                                        <span className="value">{moment(subadmin?.created_at).format('Do MMMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile