import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../../utils/api'
import ContentWrapper from '../../components/contentwrapper/ContentWrapper'

const DeliveryStatus = () => {
    const [status, setStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const subadminid = localStorage.getItem('subadmindata');
    const navigate = useNavigate();

    useEffect(() => {
        if (subadminid) {
            navigate('/all-subadmin-delivery-status');
            getAllDeliveryStatus()
        }
        else {
            navigate("/");
        }
    }, [subadminid]);

    const getAllDeliveryStatus = () => {
        setIsLoading(true)
        fetchAllDataAPI('sub-admin-all-delivery-status').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setStatus(...[res.data.statusdata]);
            }
            else {
                setIsLoading(false);
                toast.warning("Check your connection");
            }
        })
    }
    const handleDeliveryStatusActive = (statusid) => {
        activeStatus('sub-admin-active-delivery-status-status', statusid).then((res) => {
            if (res.data.status === 1) {
                getAllDeliveryStatus()
            }
        })
    }
    const handleDeliveryStatusInactive = (statusid) => {
        activeStatus('sub-admin-inactive-delivery-status-status', statusid).then((res) => {
            if (res.data.status === 1) {
                getAllDeliveryStatus()
            }
        })
    }
    const handleDeliveryStatusRemove = (statusid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('sub-admin-remove-delivery-status', statusid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllDeliveryStatus()
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="companypage">
            <Helmet>
                <title>SATKARTAR:SUBADMIN::DELIVERY STATUS</title>
            </Helmet>
            <ContentWrapper>
                <div className="card hrpages">
                    <div className="heading d-flex justify-content-between mb-2">
                        <h5 className="mb-0 text-uppercase">All Delivery Status</h5>
                        <Link to="/add-subadmin-delivery-status" className="btn btn-sm btn-primary pull-right"><AiOutlinePlus /> Add Delivery Status</Link>
                    </div>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped table-bordered table-sm" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Courier Name</th>
                                    <th>Status Name</th>
                                    <th>Status Code</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                            <td><Skeleton animation="wave" variant="text" /></td>
                                        </tr>
                                    </>


                                    :
                                    status?.map((status, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{status?.couriername}</td>
                                            <td>{status?.dstatus_name}</td>
                                            <td>{status?.delivery_status_code}</td>
                                            <td>
                                                {(() => {
                                                    if (status?.delivery_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success statustype" onClick={() => handleDeliveryStatusActive(status?.status_id)}>{status?.delivery_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger statustype" onClick={() => handleDeliveryStatusInactive(status?.status_id)}>{status?.delivery_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-subadmin-delivery-status/${status?.status_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleDeliveryStatusRemove(status?.status_id)}><AiFillDelete /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Courier Name</th>
                                    <th>Status Name</th>
                                    <th>Status Code</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default DeliveryStatus