import React from 'react'
import "../../App.scss";
import Footer from '../Footer';
import Sidebar from '../Sidebar';

const ContentWrapper = ({ children }) => {
    return (
        <div className="contentWrapper">
            <Sidebar />
            <div className="page-wrapper">
                <div className="page-content">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContentWrapper