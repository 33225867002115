import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaLanguage, FaRegHandPointRight } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaHouseDamage } from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { editDataFromList, updateDataAPI } from '../../utils/api';
import ContentWrapper from '../../components/contentwrapper/ContentWrapper';
import { GiMushroomGills } from 'react-icons/gi';

const initialState = {
    chanel_name: '',
}

const EditProblem = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        getSingleProblem(slug)
    }, [slug])

    
    const getSingleProblem = (slug) => {
        editDataFromList(`sub-admin-get-single-problem-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.problemdata])
            }
        })
    }

    const handleInputs = async (e) => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            updateDataAPI(`sub-admin-update-problem-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate("/all-subadmin-problem");
                    toast.success("Problem has been updated successfully!!")
                }
                else if (res.data.status === 2) {
                    toast.warning("Problem already Exit!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
        setValidated(true);
    }
    return (
        <div className="addCompany">
            <Helmet>
                <title>SATKARTAR:SUB ADMIN::Edit Problem</title>
            </Helmet>

            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body formbody">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <GiMushroomGills className="formIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Problem</h5>
                            </div>
                            <hr />
                            <Form noValidate validated={validated} method="POST" onSubmit={handleUpdateSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationLanguageName">
                                        <Form.Label>Problem Name</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend"><GiMushroomGills /></InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Problem Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                name="problem_name"
                                                onChange={handleInputs}
                                                value={values?.problem_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid problem name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    
                                </Row>
                                <Button type="submit">Update</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditProblem